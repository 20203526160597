import React from "react";
import Helmet from "react-helmet";
import EazyHeader from "../../components/Header";
import Layout from "../../components/Layout";
import WithProviders from "../../components/WithProviders";
import "./terms.css";
import { Message } from "../../lib/getMessages";

const TermsCondition = (props) => {
  const { language, updateLanguage, direction } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      isDirectionRTL={isDirectionRTL}
      language={language}
      location={props.location}
    >
      <div className="eazy-page">
        <Helmet title="Terms and Conditions Zopping" />
        <EazyHeader
          language={language}
          updateLanguage={updateLanguage}
          current=""
          isDirectionRTL={isDirectionRTL}
        />
        <div className="terms-condition">
          <h2>
            <Message dictKey={"terms.conditions"} />
          </h2>
          <p>
            <Message dictKey={"terms.conditions.about-1"} />
          </p>
          <p>
            <Message dictKey={"terms.conditions.about-2"} />
          </p>
          <h3>
            <Message dictKey={"terms.conditions.minimumAge"} />
          </h3>
          <p>
            <Message dictKey={"terms.conditions.minimumAge.about"} />
          </p>
          <h4>
            <Message dictKey={"terms.conditions.misuse"} />
          </h4>
          <p>
            <Message dictKey={"terms.conditions.misuse.about"} />
          </p>
          <h4>
            <Message dictKey={"terms.conditions.liability"} />
          </h4>
          <ul>
            <li>
              <Message dictKey={"terms.conditions.liability-1"} />
            </li>
            <li>
              <Message dictKey={"terms.conditions.liability-2"} />
            </li>
            <li>
              <Message dictKey={"terms.conditions.liability-3"} />
            </li>
          </ul>
          <h4>
            <Message dictKey={"terms.conditions.refund"} />
          </h4>
          <ul>
            <li>
              <Message dictKey={"terms.conditions.refund-1"} />
            </li>
            <li>
              <Message dictKey={"terms.conditions.refund-2"} />
            </li>
            <li>
              <Message dictKey={"terms.conditions.refund-3"} />
            </li>
            <li>
              <Message dictKey={"terms.conditions.refund-4"} />
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(TermsCondition);
